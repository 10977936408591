import React, { useEffect } from "react"
import {graphql,Link} from "gatsby"
import Layout from "../layout/layout"
export const query = graphql`
      query($slug1:String!,$postdate:Date!) {
        markdownRemark(fields: {slug: {eq: $slug1} }) {
         frontmatter {
            title
            date(formatString: "Do MMM, YYYY")
            categories
            thumbnail
          }
            excerpt
            html
            timeToRead
        }
        next:allMarkdownRemark(limit: 1,
          filter: {frontmatter: {date: {gte: $postdate}, slug: {ne: $slug1}}},
          sort: {fields: frontmatter___date, order: ASC}) {
            nodes {
              fields {
                slug
              }
            }
        }
        prev:allMarkdownRemark(limit: 1,
          filter: {frontmatter: {date: {lte: $postdate}, slug: {ne: $slug1}}},
          sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
              fields {
                slug
              }
            }
        }
      }
    `
const pcircle=``

const Blog=(props) =>{
  const postdata=props.data.markdownRemark.frontmatter;
  const postnext=props.data.next.nodes.length?props.data.next.nodes[0].fields.slug:'';
  const postprev=props.data.prev.nodes.length?props.data.prev.nodes[0].fields.slug:'';

  useEffect(()=>{
    const circle = document.getElementById('progress-circle')
    const length = circle.getTotalLength()+2;
    circle.style.strokeDasharray = circle.style.strokeDashoffset = length;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    console.log('Hello');
    const blogScroll = () => {
      let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      let scrollpercent = (winScroll / height);
      circle.style.strokeDashoffset = length - (length * scrollpercent);
      document.getElementById('scroll-top').style.stroke=(window.scrollY>600)?'black':'transparent';
    }
    const svgp=document.getElementById('svg-progress');
    svgp.onmouseover=()=>document.documentElement.style.scrollBehavior="smooth"
    svgp.onmouseout=()=>document.documentElement.style.scrollBehavior="initial"
    svgp.onclick=()=>window.scrollTo(0, 0);
    window.addEventListener('scroll', blogScroll)
    return()=>{window.removeEventListener('scroll', blogScroll)}
  },[])

  //console.log("varible passed by context : "+props.pageContext.postdate)
    return(
        <Layout title={postdata.title} postPath={props.pageContext.slug1} postNode={props.data.markdownRemark} postSEO>
          <div className="bpost">
          <img src={`/thumbnails/${postdata.thumbnail}`} alt={postdata.title}/>
          <div style={{'flex':'1'}}>
            <h1>{postdata.title}<br/><span>- {postdata.date} | <span style={{'color':'#0fca95'}}>{props.data.markdownRemark.timeToRead}min</span> read</span></h1>
            <div style={{'textAlign':'right'}}>
              <Link to={`/${postnext}`} className="main_links">{postnext===''?"Home":"New"}</Link>
              {postprev!==''?<Link to={`/${postprev}`} className="main_links">Old</Link>:""}
            </div>
          </div>
          </div>
            <div className="mypost" dangerouslySetInnerHTML={{__html:props.data.markdownRemark.html}}></div>
            <style>
              {pcircle}
            </style>
            <svg viewBox="0 0 50 50" id="svg-progress">
              <circle id="progress-circle" cx="25" cy="25" r="20"></circle>
              <path d="M20 28 L25 23 L30 28" id="scroll-top"></path>
            </svg>
            <div style={{'display':'flex','justifyContent':'space-between','margin':'45px 0'}}>
              <Link to={`/${postnext}`} className="main_links">{postnext===''?"Home":"New"}</Link>
              {postprev!==''?<Link to={`/${postprev}`} className="main_links">Old</Link>:""}
            </div>
        </Layout>
    )
}
export default Blog;